<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card height="100%">
          <v-card-title class="pa-0">
            <v-toolbar dark>
              <v-toolbar-title>{{ item.business_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-title class="pa-0">
            <v-app-bar flat>
              <v-tabs show-arrows v-model="tab">
                <v-tab key="contact">
                  <v-icon left> mdi-account </v-icon>
                </v-tab>
                <v-tab key="software">
                  <v-icon> mdi-certificate-outline </v-icon>
                </v-tab>
                <v-tab key="logo">
                  <v-icon> mdi-panorama-variant-outline</v-icon>
                </v-tab>
                <v-tab @click="get_resolutions" key="resolutions">
                  <v-icon> mdi-file-multiple-outline</v-icon>
                </v-tab>
                <v-tab key="inbox">
                  <v-icon> mdi-inbox-arrow-down-outline</v-icon>
                </v-tab>
                <v-tab key="ambiente">
                  <v-icon> mdi-application-cog-outline</v-icon>
                </v-tab>
              </v-tabs>
            </v-app-bar>
          </v-card-title>

          <v-card-text class="pa-0">
            <v-tabs-items v-model="tab">
              <v-tab-item key="contact">
                <v-card>
                  <v-card-title>
                    Compañía
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab" color="success" dark fab> DIAN </v-btn>
                      </template>
                      <v-btn
                        @click="createCompany(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-store-plus-outline</v-icon>
                        <span>Create</span>
                      </v-btn>
                      <v-btn
                        @click="getCompany(item)"
                        color="orange"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-store-plus-outline</v-icon>
                        <span>GET</span>
                      </v-btn>
                      <v-btn
                        @click="updateCompany(item)"
                        color="info"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                        <span>Update</span>
                      </v-btn>
                      <v-btn
                        @click="deleteCompany(item)"
                        color="error"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                        <span>Delete</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-select
                          v-model="item.type_document_identification_id"
                          hide-details="auto"
                          label="Tipo Documento"
                          :items="setting['type_document_identifications']"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="item.merchant_registration"
                          :rules="f_required"
                          label="ID"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.business_name"
                          label="Nombre"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.address"
                          label="Dirección"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="item.municipality_id"
                          hide-details="auto"
                          label="Ciudad"
                          :items="setting['municipalities']"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.email"
                          id="name"
                          :rules="f_required"
                          label="Correo electrónico"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="item.phone"
                          label="Teléfono"
                          hide-details="auto"
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="item.pos"
                          label="POS"
                          hide-details="auto"
                          :rules="f_required"
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="item.type_organization_id"
                          hide-details="auto"
                          label="Tipo persona"
                          :items="setting['type_organizations']"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="item.type_regime_id"
                          hide-details="auto"
                          label="Tipo de régimen"
                          :items="setting['type_regimes']"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                          :disabled="!edit"
                        />
                      </v-col>
                      resolution
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="item.type_liability_id"
                          hide-details="auto"
                          label="Tipo de responsabilidad"
                          :items="setting['type_liabilities']"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.token"
                          label="Token"
                          hide-details="auto"
                          outlined
                          :disabled="!edit"
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="software">
                <v-card>
                  <v-card-title>
                    Software
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab" color="success" dark fab> Dian </v-btn>
                      </template>
                      <v-btn
                        @click="put_software(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-store-plus-outline</v-icon>
                        <span>Registrar</span>
                      </v-btn>
                      <v-btn
                        @click="get_software(item)"
                        color="info"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                        <span>Consultar</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.id"
                          label="Software"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.pin"
                          label="PIN"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.url"
                          label="URL"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-title>
                    Certificado Digital
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab_c"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab_c" color="success" dark fab> Dian </v-btn>
                      </template>
                      <v-btn
                        @click="put_certificate(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-update</v-icon>
                        <span>Actualizar</span>
                      </v-btn>
                      <v-btn
                        @click="get_certificate(item)"
                        color="info"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        <span>Consultar</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-textarea
                          v-model="item.certificate"
                          class="text--sm"
                          :disabled="!edit"
                          label="Certificate (.p12) in base64"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-file-input
                          v-model="chosenFile"
                          counter
                          @input="converto"
                          label="Archivo .p12"
                          placeholder="Seleccione el archivo"
                          append-outer-icon="mdi-upload"
                          @click:append-outer="converto"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip v-if="index < 2" label small>
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ chosenFile.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.password"
                          label="Password"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="logo">
                <v-card>
                  <v-card-title>
                    Logo
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab_c"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab_c" color="success" dark fab> Dian </v-btn>
                      </template>
                      <v-btn
                        @click="put_logo(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-update</v-icon>
                        <span>Actualizar</span>
                      </v-btn>
                      <v-btn
                        @click="get_logo(item)"
                        color="info"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        <span>Consultar</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-textarea
                          v-model="item.logo"
                          class="text--sm"
                          :disabled="!edit"
                          label="Logo (700 * 300 png) en base64"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-file-input
                          v-model="chosenPhoto"
                          counter
                          @input="PhotoToBase64"
                          label="Logo (700 * 300 png) en base64"
                          placeholder="Seleccione el logo"
                          append-outer-icon="mdi-upload"
                          @click:append-outer="PhotoToBase64"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip v-if="index < 2" label small>
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ chosenFile.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="resolutions">
                <v-data-table
                  :headers="headers_resolution[$vuetify.breakpoint.xsOnly]"
                  :items="resolutions_lst"
                  :items-per-page="10"
                  item-key="type_document_id,resolution"
                  sort-by="name"
                  mobile-breakpoint="10"
                  class="table-cursor"
                  :loading="loading_status"
                  loader-height="10"
                  loading-text="Cargando ..."
                  @click:row="edit_resolution"
                >
                  <template v-slot:top>
                    <v-toolbar elevation="0">
                      <h1>Resoluciones</h1>
                      <v-spacer></v-spacer>
                      <v-btn @click="getResolutions()" class="info mr-2">
                        <v-icon>mdi-file-download-outline</v-icon>
                        <span>Consultar</span>
                      </v-btn>
                      <v-btn @click="newResolution()" class="success">Nueva</v-btn>
                    </v-toolbar>
                  </template>
                  <!-- <template v-slot:[`item.type_document_id`]="{ item }">
                    {{
                      setting["type_documents"].filter(function (data) {
                        return data.id == item.type_document_id;
                      })[0].name
                    }}
                  </template> -->
                </v-data-table>
              </v-tab-item>
              <v-tab-item key="inbox">
                <v-card>
                  <v-card-title>
                    Recepción - IMAP
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab" color="success" dark fab> Dian </v-btn>
                      </template>
                      <v-btn
                        @click="put_imap(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-store-plus-outline</v-icon>
                        <span>Create</span>
                      </v-btn>
                      <v-btn
                        @click="delete_imap(item)"
                        color="red"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                        <span>Delete</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" md="8">
                        <v-text-field
                          v-model="item.imap_host"
                          label="Host"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="8" md="4">
                        <v-text-field
                          v-model="item.imap_port"
                          label="Port"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.imap_username"
                          label="Username"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.imap_password"
                          label="Password"
                          hide-details="auto"
                          required
                          outlined
                          type="password"
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-title>
                    Envio - SMTP
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab_c"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab_c" color="success" dark fab> Dian </v-btn>
                      </template>
                      <v-btn
                        @click="put_smtp(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-store-plus-outline</v-icon>
                        <span>Create</span>
                      </v-btn>
                      <v-btn
                        @click="delete_smtp(item)"
                        color="red"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                        <span>Delete</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" md="4">
                        <v-text-field
                          v-model="item.smtp_host"
                          label="Host"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="8" md="4">
                        <v-text-field
                          v-model="item.smtp_port"
                          label="port"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="item.smtp_encryption"
                          hide-details="auto"
                          label="Encryption"
                          :items="encryption"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.smtp_username"
                          label="Username"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.smtp_password"
                          label="Password"
                          hide-details="auto"
                          required
                          outlined
                          type="password"
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.smtp_from_name"
                          label="From Name"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.smtp_from_address"
                          label="From Address"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="ambiente">
                <v-card>
                  <v-card-title>
                    Ambiente
                    <v-spacer></v-spacer>
                    <v-speed-dial
                      v-model="fab"
                      direction="bottom"
                      open-on-hover
                      transition="slide-y-reverse-transition"
                      class="pr-6"
                    >
                      <template v-slot:activator>
                        <v-btn v-model="fab" color="success" dark fab> Dian </v-btn>
                      </template>
                      <v-btn
                        @click="put_environment(item)"
                        color="success"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-store-plus-outline</v-icon>
                        <span>Registrar</span>
                      </v-btn>
                      <v-btn
                        @click="get_environment(item)"
                        color="info"
                        class="mr-12"
                        :disabled="newItem"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                        <span>Consultar</span>
                      </v-btn>
                    </v-speed-dial>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-select
                          v-model="item.type_environment_id"
                          hide-details="auto"
                          label="Ambiente de operación"
                          :items="setting['type_environments']"
                          item-value="id"
                          item-text="name"
                          :rules="f_required"
                          outlined
                          required
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.technical_key"
                          label="Clave Técnica"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.resolution"
                          label="Resolución Facturación"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.TestSetId"
                          label="TestSetId"
                          hide-details="auto"
                          required
                          outlined
                          :disabled="!edit"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="delete_itm(item)"
              :disabled="newItem"
              v-if="this.$store.state.profile.user_access === 'Owner'"
            >
              <span>Borrar</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn @click="edit = !edit" class="info">
              <span>Editar</span>
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn type="submit" class="success">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="600" v-model="edit_resolution_form">
      <v-form v-model="validResolution" ref="resolutionForm" @submit="resolution_submit">
        <v-card>
          <v-card-title>
            Resolución
            <v-spacer></v-spacer>
            <v-btn
              text
              color="red"
              type="button"
              @click="edit_resolution_form = !edit_resolution_form"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  v-model="resolution.type_document_id"
                  hide-details="auto"
                  label="Tipo Documento"
                  :items="setting['type_documents']"
                  item-value="id"
                  item-text="name"
                  :rules="f_required"
                  outlined
                  required
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="resolution.resolution"
                  label="Resolución"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="resolution.technical_key"
                  label="Llave técnica"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="resolution.prefix"
                  :rules="f_required"
                  label="Prefijo"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  v-model="resolution.from_number"
                  label="Desde"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  v-model="resolution.to_number"
                  label="Hasta"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="resolution.resolution_date"
                  type="date"
                  :rules="f_required"
                  label="Fecha Resolución"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  v-model="resolution.date_from"
                  label="Valida desde"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  v-model="resolution.date_to"
                  label="Valida hasta"
                  hide-details="auto"
                  required
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-speed-dial
              v-model="fab"
              direction="top"
              open-on-hover
              transition="slide-y-reverse-transition"
              class="pr-6"
            >
              <template v-slot:activator>
                <v-btn v-model="fab" color="success" dark fab> DIAN </v-btn>
              </template>
              <v-btn
                @click="createResolution(resolution)"
                color="success"
                class="mr-12"
                :disabled="newItem"
              >
                <v-icon>mdi-store-plus-outline</v-icon>
                <span>Create</span>
              </v-btn>
              <v-btn
                @click="updateResolution(resolution)"
                color="info"
                class="mr-12"
                :disabled="newItem"
              >
                <v-icon>mdi-delete-outline</v-icon>
                <span>Update</span>
              </v-btn>
              <v-btn
                @click="deleteResolution(resolution)"
                color="error"
                class="mr-12"
                :disabled="newItem"
              >
                <v-icon>mdi-delete-outline</v-icon>
                <span>Delete</span>
              </v-btn>
            </v-speed-dial>

            <v-spacer></v-spacer>
            <v-btn type="submit" class="success">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="snackbar"
      max-width="800px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark>
            <v-toolbar-title>{{ item.business_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="snackbar = !snackbar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <pre>{{ apiResponse }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { fe_ws } from "../services/webserver.js";
import createItem from "../utils/fe_empresa.js";
import createSetting from "../utils/createSetting.js";
import createResolutionItem from "../utils/createResolution.js";
export default {
  name: "FECompany",
  components: {},
  data() {
    return {
      fab: false,
      fab_c: false,
      dialog_response: false,
      dialog_confirm: false,
      apiResponse: { message: "" },
      edit: false,
      menuPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      productPhoto: null,
      viewMap: false,
      recenter: false,
      ipgeo: null,
      countries: [],
      state: [],
      city: [],
      loading_status: false,
      tab: null,
      reg_delete: null,
      valid: false,
      encryption: ["ssl", "tls", "starttls", "notls"],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      stloc: null,
      snackbar: false,
      timeout: 5000,
      chosenFile: null, // <- initialize the v-model prop
      chosenPhoto: null,
      dta: null,
      headers_resolution: {
        false: [
          {
            text: "ID",
            align: "center",
            sortable: true,
            value: "id",
            dataType: "number",
            width: 30,
          },
          {
            text: "Tipo",
            align: "center",
            sortable: true,
            value: "type_document_id",
            dataType: "text",
            width: 30,
          },
          {
            text: "Prefijo",
            align: "start",
            sortable: true,
            value: "prefix",
            dataType: "text",
            width: 20,
          },
          {
            text: "Resolución",
            align: "start",
            sortable: true,
            value: "resolution",
            dataType: "text",
          },
          {
            text: "Fecha Resolución",
            align: "center",
            sortable: true,
            value: "resolution_date",
            dataType: "date",
          },
          {
            text: "Llave técnica",
            align: "center",
            sortable: true,
            value: "technical_key",
            dataType: "text",
            width: 20,
          },
          {
            text: "Desde",
            align: "center",
            sortable: true,
            value: "from_number",
            dataType: "text",
            width: 20,
          },
          {
            text: "Hasta",
            align: "center",
            sortable: true,
            value: "to_number",
            dataType: "text",
            width: 20,
          },
          {
            text: "Desde",
            align: "center",
            sortable: true,
            value: "date_from",
            dataType: "text",
          },
          {
            text: "Hasta",
            align: "center",
            sortable: true,
            value: "date_to",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "business_name",
            dataType: "text",
          },
        ],
      },
      validResolution: false,
      resolutions_lst: [],
      resolution: createResolutionItem(),
      edit_resolution_form: false,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    setting: {
      type: Object,
      required: true,
      default: createSetting(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.$emit("itmUpdated", this.item);
        var qry = {
          table: "companies",
          data: [this.item],
        };
        this.loading_status = true;
        fe_ws("put_to_table", qry, () => {
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      fe_ws("delete_record", qry, () => {
        this.reg_delete = null;
        //this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    getFiles(files) {
      console.log(files);
    },
    createCompany(itm) {
      var dta = {
        type_document_identification_id: itm.type_document_identification_id,
        type_organization_id: itm.type_organization_id,
        type_regime_id: itm.type_regime_id,
        type_liability_id: itm.type_liability_id,
        business_name: itm.business_name,
        merchant_registration: itm.merchant_registration,
        municipality_id: itm.municipality_id,
        address: itm.address,
        phone: itm.phone,
        email: itm.email,
      };
      var qry = {
        method: "POST",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      fe_ws("admin_company", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    getCompany(itm) {
      var qry = {
        method: "GET",
        nit: itm.merchant_registration,
      };
      this.loading_status = true;
      fe_ws("admin_company", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
      });
    },
    updateCompany(itm) {
      var dta = {
        type_document_identification_id: itm.type_document_identification_id,
        type_organization_id: itm.type_organization_id,
        type_regime_id: itm.type_regime_id,
        type_liability_id: itm.type_liability_id,
        business_name: itm.business_name,
        merchant_registration: itm.merchant_registration,
        municipality_id: itm.municipality_id,
        address: itm.address,
        phone: itm.phone,
        email: itm.email,
      };
      var qry = {
        method: "PUT",
        nit: itm.merchant_registration,
        data: dta,
      };
      //console.log(qry);
      this.loading_status = true;
      fe_ws("admin_company", qry, (data) => {
        //console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    deleteCompany() {
      var qry = {
        method: "DELETE",
        nit: this.item.merchant_registration,
      };
      this.loading_status = true;
      fe_ws("admin_company", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        this.$emit("refresh", true);
      });
    },
    statusCompany() {
      var qry = {
        method: "GET",
        nit: this.item.merchant_registration,
        data: null,
      };
      this.loading_status = true;
      fe_ws("admin_company", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        if (data.message) {
          this.dialog_response = true;
          this.apiResponse = data;
          this.snackbar = true;
        }
      });
    },
    put_software(itm) {
      var dta = {
        id: itm.id,
        pin: itm.pin,
      };
      var qry = {
        method: "POST",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      fe_ws("put_software", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    get_software(itm) {
      var dta = null;
      var qry = {
        method: "GET",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      console.log(qry);
      fe_ws("get_software", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    put_certificate(itm) {
      var dta = {
        certificate: itm.certificate,
        password: itm.password,
      };
      var qry = {
        method: "POST",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      fe_ws("put_certificate", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    get_certificate(itm) {
      var dta = null;
      var qry = {
        method: "GET",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      fe_ws("get_certificate", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    put_logo(itm) {
      var dta = {
        logo: itm.logo,
      };
      var qry = {
        method: "PUT",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      fe_ws("put_logo", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    get_logo(itm) {
      var dta = null;
      var qry = {
        method: "GET",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      fe_ws("get_logo", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    closeResponse() {
      this.apiResponse = { message: "" };
      this.dialog_response = false;
    },
    close() {
      this.$emit("close", true);
    },
    converto() {
      if (!this.chosenFile) {
        this.data = "No File Chosen";
      }
      this.getBase64(this.chosenFile).then((data) => {
        this.item.certificate = data.split(",")[1];
      });
    },
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }
      this.getBase64(this.chosenPhoto).then((data) => {
        this.item.logo = data.split(",")[1];
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    newResolution() {
      this.resolution = createResolutionItem();
      this.edit_resolution_form = true;
    },
    edit_resolution(e) {
      this.resolution = { ...e };
      this.edit_resolution_form = true;
    },
    resolution_submit(e) {
      e.preventDefault();
      this.validResolution = this.$refs.resolutionForm.validate();
      this.resolution.merchant_registration = this.item.merchant_registration;
      if (this.validResolution) {
        var qry = {
          table: "resolutions",
          data: [this.resolution],
        };
        this.loading_status = true;
        fe_ws("put_to_table", qry, () => {
          this.loading_status = false;
          this.resolution = createResolutionItem();
          this.edit_resolution_form = false;
          this.get_resolutions();
        });
      }
    },
    get_resolutions() {
      var qry = {
        table: "resolutions",
        filters: [
          {
            field: "merchant_registration",
            operator: "=",
            value: this.item.merchant_registration,
          },
        ],
      };
      this.loading_status = true;
      console.log(qry);
      fe_ws("get_table", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.resolutions_lst = data;
      });
    },
    createResolution(itm) {
      var reso = {
        technical_key: itm.technical_key,
        prefix: itm.prefix,
        resolution: parseFloat(itm.resolution),
        resolution_date: itm.resolution_date,
        date_from: itm.date_from,
        date_to: itm.date_to,
        type_document_id: parseFloat(itm.type_document_id),
        from: parseFloat(itm.from_number),
        to: parseFloat(itm.to_number),
      };

      var qry = {
        method: "POST",
        nit: this.item.merchant_registration,
        data: reso,
      };
      console.log(qry);
      this.loading_status = true;
      fe_ws("adm_resolution", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;

        this.apiResponse = JSON.parse(data.data);
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    updateResolution(itm) {
      console.log(itm);
      var reso = {
        id: itm.id,
        technical_key: itm.technical_key,
        resolution: parseFloat(itm.resolution),
        prefix: itm.prefix,
        resolution_date: itm.resolution_date,
        date_from: itm.date_from,
        date_to: itm.date_to,
        type_document_id: parseFloat(itm.type_document_id),
        from: parseFloat(itm.from_number),
        to: parseFloat(itm.to_number),
      };
      var qry = {
        method: "PUT",
        nit: itm.merchant_registration,
        resolution_id: parseInt(itm.id),
        data: reso,
      };
      console.log(qry);
      this.loading_status = true;
      fe_ws("adm_resolution", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = JSON.parse(data.data);
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    deleteResolution(itm) {
      // var dta = {
      //   type_document_identification_id: itm.type_document_identification_id,
      //   type_organization_id: itm.type_organization_id,
      //   type_regime_id: itm.type_regime_id,
      //   type_liability_id: itm.type_liability_id,
      //   business_name: itm.business_name,
      //   merchant_registration: itm.merchant_registration,
      //   municipality_id: itm.municipality_id,
      //   address: itm.address,
      //   phone: itm.phone,
      //   email: itm.email,
      // };
      var qry = {
        method: "DELETE",
        nit: this.item.merchant_registration,
        resolution_id: parseInt(itm.id),
      };
      this.loading_status = true;
      fe_ws("adm_resolution", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.$emit("refresh", true);
      });
    },
    getResolutions() {
      var qry = {
        method: "GET",
        nit: this.item.merchant_registration,
        data: null,
      };
      this.loading_status = true;
      fe_ws("get_all_resolutions", qry, (data) => {
        this.loading_status = false;
        console.log(">>>", data);

        this.dialog_response = true;
        this.apiResponse = JSON.parse(data.data);
        this.snackbar = true;

        var resolu = JSON.parse(data.data);
        resolu.forEach((rg) => {
          rg.merchant_registration = this.item.merchant_registration;
          rg.from_number = rg.from;
          rg.to_number = rg.to;
          rg.last_number = rg.number;
          delete rg.from;
          delete rg.to;
          delete rg.number;
        });
        console.log("===>", resolu);
        this.inser2tablest("resolutions", resolu);
      });
    },
    inser2tablest(tbl, dt) {
      var qry = {
        table: tbl,
        data: dt,
      };
      console.log(qry);
      fe_ws("put_to_table", qry, () => {
        this.get_resolutions();
      });
    },
    put_environment(itm) {
      var dta = {
        type_environment_id: parseInt(itm.type_environment_id),
      };
      var qry = {
        method: "PUT",
        nit: itm.merchant_registration,
        data: dta,
      };
      this.loading_status = true;
      console.log(qry);
      fe_ws("put_environment", qry, (data) => {
        console.log(data.data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    get_environment() {
      var qry = {
        method: "GET",
        nit: this.item.merchant_registration,
        data: null,
      };
      console.log(qry);
      this.loading_status = true;
      fe_ws("get_environment", qry, (data) => {
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
        //this.$emit("refresh", true);
      });
    },
    put_imap(itm) {
      var inbox = {
        host: itm.imap_host,
        port: itm.imap_port,
        username: itm.imap_username,
        password: itm.imap_password,
      };

      var qry = {
        method: "PUT",
        nit: itm.merchant_registration,
        data: inbox,
      };
      console.log(qry);
      this.loading_status = true;
      fe_ws("put_imap", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
      });
    },
    delete_imap(itm) {
      var smtp = {
        host: itm.smtp_host,
        port: itm.smtp_port,
        username: itm.smtp_username,
        password: itm.smtp_password,
        encryption: itm.smtp_encryption,
        from_address: itm.smtp_from_address,
        from_name: itm.smtp_from_name,
      };

      var qry = {
        method: "DELETE",
        nit: itm.merchant_registration,
        data: smtp,
      };
      this.loading_status = true;
      fe_ws("delete_imap", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
      });
    },
    put_smtp(itm) {
      var smtp = {
        host: itm.smtp_host,
        port: itm.smtp_port,
        username: itm.smtp_username,
        password: itm.smtp_password,
        encryption: itm.smtp_encryption,
        from_address: itm.smtp_from_address,
        from_name: itm.smtp_from_name,
      };

      var qry = {
        method: "PUT",
        nit: itm.merchant_registration,
        data: smtp,
      };
      this.loading_status = true;
      fe_ws("put_smtp", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
      });
    },
    delete_smtp(itm) {
      var smtp = {
        host: itm.smtp_host,
        port: itm.smtp_port,
        username: itm.smtp_username,
        password: itm.smtp_password,
        encryption: itm.smtp_encryption,
        from_address: itm.smtp_from_address,
        from_name: itm.smtp_from_name,
      };

      var qry = {
        method: "DELETE",
        nit: itm.merchant_registration,
        data: smtp,
      };
      this.loading_status = true;
      fe_ws("delete_smtp", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.dialog_response = true;
        this.apiResponse = data;
        this.snackbar = true;
      });
    },
  },
  watch: {
    item: function () {
      this.statusCompany();
      console.log(this.setting);
      this.get_resolutions();
    },
  },
};
</script>
<style scoped>
.file-input {
  display: none;
}
</style>
