<template>
  <v-container>
    <v-toolbar flat>
      <h1>Empresas</h1>
      <v-spacer></v-spacer>
      <v-btn icon @click="newForm">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="items"
      :items-per-page="10"
      item-key="code"
      sort-by="name"
      mobile-breakpoint="10"
      class="table-cursor"
      :search="search"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
      @click:row="handleClick"
    >
      <template v-slot:[`item.token`]="{ item }">
        <v-icon v-if="item.token"> mdi-thumb-up-outline </v-icon>
      </template>
      <template v-slot:[`item.business_name`]="{ item }">
        {{ item.business_name }} <br />
        <small> {{ item.email }} </small><br />
        <small>
          {{ item.technical_key }}
        </small>
      </template>

      <template v-slot:[`item.type_document_identification_id`]="{ item }">
        {{
          setting["type_documents"].filter(function (data) {
            return data.id === item.type_document_identification_id;
          })[0].name
        }}
      </template>

      <template v-slot:[`item.type_organization_id`]="{ item }">
        {{
          setting["type_organizations"].filter(function (data) {
            return data.id == item.type_organization_id;
          })[0].name
        }}
      </template>
      <template v-slot:[`item.type_regime_id`]="{ item }">
        {{
          setting["type_regimes"].filter(function (data) {
            return data.id == item.type_regime_id;
          })[0].name
        }}
      </template>
      <template v-slot:[`item.type_liability_id`]="{ item }">
        {{
          setting["type_liabilities"].filter(function (data) {
            return data.id == item.type_liability_id;
          })[0].name
        }}
      </template>
    </v-data-table>

    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :setting="setting"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { fe_ws } from "../../services/webserver.js";
import createItem from "../../utils/fe_empresa.js";
import createSet from "../../utils/createSettingFE.js";
import PartyForm from "../../components/FE_companyForm.vue";
export default {
  components: { PartyForm },
  mounted() {
    this.get_settings();
  },
  data() {
    return {
      table: "companies",
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      itms: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "ID",
            align: "start",
            sortable: true,
            value: "merchant_registration",
            dataType: "text",
            width: 20,
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "business_name",
            dataType: "text",
          },
          {
            text: "POS",
            align: "start",
            sortable: true,
            value: "pos",
            dataType: "text",
          },
          {
            text: "Tipo",
            align: "start",
            sortable: true,
            value: "type_environment_id",
            dataType: "text",
          },

          {
            text: "Tipo",
            align: "center",
            sortable: true,
            value: "type_document_identification_id",
            dataType: "text",
            width: 30,
          },
          {
            text: "Relosución",
            align: "left",
            sortable: true,
            value: "resolution",
            dataType: "text",
            width: 30,
          },

          // {
          //     text: "Tipo de Organización",
          //     align: "center",
          //     sortable: true,
          //     value: "type_organization_id",
          //     dataType: "text",
          //     width: 20,
          // },
          // {
          //     text: "Tipo de Régimen",
          //     align: "center",
          //     sortable: true,
          //     value: "type_regime_id",
          //     dataType: "text",
          //     width: 20,
          // },
          // {
          //     text: "Tipo de Responsabilidad",
          //     align: "center",
          //     sortable: true,
          //     value: "type_liability_id",
          //     dataType: "text",
          //     width: 20,
          // },

          {
            text: "Status",
            align: "center",
            sortable: true,
            value: "last_message",
            dataType: "text",
          },
          {
            text: "Token",
            align: "center",
            sortable: true,
            value: "token",
            dataType: "text",
            width: 20,
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "business_name",
            dataType: "text",
          },
        ],
      },
      setting: createSet(),
      table_lst: [
        "type_document_identifications",
        "type_organizations",
        "type_regimes",
        "type_liabilities",
        "municipalities",
        "type_environments",
        "type_documents",
      ],
    };
  },
  methods: {
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    get_items() {
      var qry = {
        table: "companies",
        filters: [
          {
            field: "merchant_registration",
            operator: "<>",
            value: "",
          },
        ],
      };
      this.loading_status = true;
      console.log(qry);
      fe_ws("get_table", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
    async get_settings() {
      this.itms = [];
      var qry = {};
      var tbl = "";
      for (tbl of this.table_lst) {
        this.loading_status = true;
        if (tbl) {
          qry = {
            table: "settings",
            filters: [
              {
                field: "tableName",
                operator: "=",
                value: tbl,
              },
            ],
          };

          let promise = new Promise((resolve, reject) => {
            fe_ws(
              "get_table",
              qry,
              function (data) {
                resolve(data);
              },
              function () {
                reject([]);
              }
            );
          });
          var result = await promise;
          console.log(result);
          if (result) {
            this.itms.push.apply(this.itms, result);
            var rstd = this.itms.reduce(function (rv, x) {
              (rv[x["tableName"]] = rv[x["tableName"]] || []).push(x);
              return rv;
            }, {});
            for (const [key, value] of Object.entries(rstd)) {
              console.log(key);
              value.sort(this.GetSortOrder("name"));
            }
            this.setting = rstd;
          }
        }
      }
      this.get_items();
      this.loading_status = false;
    },
    refresh() {
      this.dialog = false;
      this.items = [];
      this.get_items();
    },
    newForm() {
      this.item = createItem();
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
