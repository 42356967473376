export default (data = {}) => {
    return {
        type_document_id: "1",
        prefix: null,
        resolution: null,
        resolution_date: null,
        technical_key: null,
        from_number: null,
        to_number: null,
        generated_to_date: 0,
        date_from: null,
        date_to: null,
        ...data
    }
}

